var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("router-link", { attrs: { to: { name: "home" } } }, [
            _c("span", [_vm._v("＜ トップページに戻る")]),
          ]),
          _c(
            "a",
            {
              staticClass: "price-change",
              attrs: {
                href: "https://note.com/nolanovel/n/n23caf1b03db7",
                target: "_blank",
              },
            },
            [
              _c("error-icon", { staticClass: "caution" }),
              _c("span", [_vm._v("Nolaプレミアムの価格変更について")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
      _c("div", { staticClass: "announcement section" }, [
        _c("div", { staticClass: "description" }, [
          _vm._v(
            " " +
              _vm._s(_vm.description) +
              " Nolaでは、サービスの継続的な開発や運営のため、有料機能を設けさせていただいております。プロットのカスタマイズや資料を見ながらの執筆など様々な機能がご利用いただけますので、ぜひNolaプレミアムの登録をご検討いただけますと幸いです。 "
          ),
        ]),
        _c("div", { staticClass: "transition-register" }, [
          _c("span", [_vm._v("＼　2022年7月に価格を下げました　／")]),
          _c(
            "a",
            {
              staticClass: "button-design register",
              attrs: { href: "#plan" },
              on: { click: _vm.onClickTransitionPlanSelection },
            },
            [_vm._v("詳しく確認する")]
          ),
        ]),
      ]),
      _vm._m(1),
      _c("div", { attrs: { id: "plan" } }),
      _c("div", { staticClass: "plan section" }, [
        _c("div", { staticClass: "section-title" }, [_vm._v("ご利用金額")]),
        _c("div", { staticClass: "plan-list" }, [
          _c("img", {
            staticClass: "cat-img",
            attrs: { src: require("@/assets/img/premium/cat-01.png") },
          }),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              {
                staticClass: "plan-container",
                on: {
                  click: function ($event) {
                    return _vm.onClickSubscribe("monthly")
                  },
                },
              },
              [_vm._m(2)]
            ),
            _c(
              "div",
              {
                staticClass: "plan-container",
                on: {
                  click: function ($event) {
                    return _vm.onClickSubscribe("yearly")
                  },
                },
              },
              [_vm._m(3)]
            ),
          ]),
        ]),
      ]),
      _vm._m(4),
      _vm._m(5),
      _vm._m(6),
      _c("site-footer", { staticClass: "footer" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("img", {
        staticClass: "title-img",
        attrs: { src: require("@/assets/img/premium/title.png"), alt: "title" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "feature-list section" }, [
      _c("div", { staticClass: "feature-title" }, [
        _c("img", { attrs: { src: require("@/assets/img/icon/premium.png") } }),
        _c("div", { staticClass: "section-title" }, [
          _vm._v("Nolaプレミアムでできること"),
        ]),
      ]),
      _c("div", { staticClass: "feature-content" }, [
        _c("div", { staticClass: "feature-item" }, [
          _c("span", [_vm._v("① 好きな形式で自分に合った資料を作れる")]),
          _c("div", { staticClass: "feature-image" }, [
            _c("img", {
              attrs: { src: require("@/assets/img/premium/feature-01.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/img/premium/feature-02.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "feature-item" }, [
          _c("span", [_vm._v("② 一つの画面で様々な資料を見ながら執筆できる")]),
          _c("div", { staticClass: "feature-image" }, [
            _c("img", {
              attrs: { src: require("@/assets/img/premium/feature-03.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/img/premium/feature-04.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "feature-item" }, [
          _c("span", [
            _vm._v("③ 好きな名前のフォルダに分けて作品を管理できる"),
          ]),
          _c("div", { staticClass: "feature-image" }, [
            _c("img", {
              attrs: { src: require("@/assets/img/premium/feature-05.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/img/premium/feature-06.png") },
            }),
          ]),
        ]),
        _c("div", { staticClass: "feature-item" }, [
          _c("span", [_vm._v("④ いろんな機能を無制限で利用できる")]),
          _c("div", { staticClass: "feature-image" }, [
            _c("img", {
              attrs: { src: require("@/assets/img/premium/feature-07.png") },
            }),
            _c("img", {
              attrs: { src: require("@/assets/img/premium/feature-08.png") },
            }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "plan-box" }, [
      _c("div", { staticClass: "plan-name" }, [_vm._v("プレミアム会員")]),
      _c("div", { staticClass: "plan-price" }, [
        _c("div", { staticClass: "price-wrapper" }, [
          _c("span", [_vm._v("月額")]),
          _c("span", { staticClass: "price" }, [_vm._v("300")]),
          _c("span", [_vm._v("円")]),
        ]),
      ]),
      _c("button", { staticClass: "button monthly" }, [_vm._v("登録する")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "plan-box yearly" }, [
      _c("div", { staticClass: "plan-name yearly" }, [
        _vm._v("年間プレミアム会員"),
      ]),
      _c("div", { staticClass: "plan-price" }, [
        _c("div", { staticClass: "price-wrapper" }, [
          _c("span", [_vm._v("年額")]),
          _c("span", { staticClass: "price" }, [_vm._v("2,800")]),
          _c("span", [_vm._v("円")]),
        ]),
        _c("div", { staticClass: "annotation" }, [
          _c("span", [_vm._v("月換算 234円")]),
          _c("span", [_vm._v("(月額より22%お得です)")]),
        ]),
      ]),
      _c("button", { staticClass: "button yearly" }, [_vm._v("登録する")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "feature-table section" }, [
      _c("div", { staticClass: "section-title" }, [
        _vm._v("Nolaプレミアムの機能比較表"),
      ]),
      _c("img", {
        attrs: {
          src: require("@/assets/img/premium/feature-table.png"),
          width: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section" }, [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "message section" }, [
      _c("div", { staticClass: "message-icon" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/icon/email_white.svg"),
            alt: "message",
          },
        }),
      ]),
      _c("div", { staticClass: "message-content" }, [
        _c("div", { staticClass: "beginning" }, [
          _c("span", [_vm._v("最後まで読んでくださりありがとうございます。")]),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("span", [
            _vm._v(" いつもNolaをご利用くださりありがとうございます。"),
            _c("br"),
            _vm._v(
              " また、プレミアム機能についてご検討くださり心より感謝申し上げます。"
            ),
            _c("br"),
            _vm._v(
              " ご支援いただいた金額はNolaをはじめとする各サービスの運営と開発のために大切に活用させていただきます。まだまだ機能として至らない点もあり恐れ入りますが、より快適な創作環境を目指して開発を続けていきたいと思いますので、今後とも何卒よろしくお願いいたします。 "
            ),
          ]),
        ]),
        _c("div", { staticClass: "ending" }, [
          _c("span", [_vm._v("Nola運営スタッフ一同")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }