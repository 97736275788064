import { render, staticRenderFns } from "./ErrorIcon.vue?vue&type=template&id=5430b214"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/tty215/develop/indent/nola/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5430b214')) {
      api.createRecord('5430b214', component.options)
    } else {
      api.reload('5430b214', component.options)
    }
    module.hot.accept("./ErrorIcon.vue?vue&type=template&id=5430b214", function () {
      api.rerender('5430b214', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/ErrorIcon.vue"
export default component.exports