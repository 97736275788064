
import Vue from "vue";
import { Auth } from "aws-amplify";
import { BillingClient } from "@/lib/clients";
import { Plan } from "@/lib/models/user";
import { CreateCheckoutSessionInput } from "@/lib/models/billing";
import ErrorIcon from "@/components/atoms/ErrorIcon.vue";
import SiteFooter from "@/components/organisms/Footer.vue";

const billingClient = new BillingClient();

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { ErrorIcon, SiteFooter },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    description() {
      let description = "Nolaのプレミアム会員に登録すると、有料の機能を利用できるようになります。";
      const { from } = this.$route.query;
      if (from) {
        description = "この機能は、Nolaのプレミアム会員に登録すると利用ができるようになります。";
      }
      return description;
    },
  },
  methods: {
    async onClickSubscribe(planStr) {
      const user = await Auth.currentUserPoolUser().catch((err) => false);
      const { email } = user.attributes;

      const plan = planStr === "monthly" ? Plan.monthly : Plan.yearly;

      const payload: CreateCheckoutSessionInput = {
        email,
        plan,
        successUrl: `${window.location.origin}/subscription/completed`,
        cancelUrl: `${window.location.origin}/subscription`,
      };
      const session = await billingClient.createCheckoutSession(payload);

      window.location.href = session.url;
    },
    onClickTransitionPlanSelection() {
      this.$ga.event("subscription", "transition-plan-selection", "プラン選択に移動", 0);
    },
  },
});

interface Props {}

interface Data {}

interface Computed {
  description: string;
}

interface Methods {
  onClickSubscribe(planStr: "monthly" | "yearly"): Promise<void>;
  onClickTransitionPlanSelection(): void;
}
